import { mapActions, mapGetters } from 'vuex'
import ReportExport from '../../features/report-export/index.vue'
import reportMixin from '@/mixins/reportMixin'

export default {
  components: {
    ReportExport
  },
  mixins: [reportMixin],
  computed: {
    headers () {
      return [
        {
          text: 'Title',
          value: 'name'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Points',
          value: 'points'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ]
    },
    ...mapGetters({
      userData: 'statistics/userData'
    }),
    reportFn () {
      return this.createReportFn({
        url: `${process.env.VUE_APP_ROOT_STATISTIC}courses/${this.$route.params.courseId}/users/${this.$route.params.userId}`,
        method: 'get'
      })
    }
  },
  created () {
    this.fetchUserDetails({
      courseId: this.$route.params.courseId,
      userId: this.$route.params.userId
    })
  },
  methods: {
    ...mapActions({
      fetchUserDetails: 'statistics/GET_STATISTICS_USER_DETAILS'
    })
  }
}
